import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  useMediaQuery,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
import { Add, AddCircleOutline, HdrPlus, LocalGasStation, PlusOne } from "@mui/icons-material";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";

const StartDeliveryModal = ({ onClose,order }) => {
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [asset, setAsset] = useState("");
  const [orderType, setOrderType] = useState("");
  // const [bypassTotalizer, setBypassTotalizer] = useState(false);
  const [total, setTotal] = useState("");
  // const [location2,setLocation2] = useState('');
  // const dieselPrice = 92.5

  // const isMobile = useMediaQuery("(max-width : 600px)");
  const [dieselPrice, setDieselPrice] = useState(90);
  const [discountPrice, setDiscountPrice] = useState(1);
  const { token } = useContext(AuthContext);

  const handleQty = (qty) => {
    console.log(".....................", qty);
    setQuantity(qty);

    setTotal(parseFloat(dieselPrice) * qty - parseFloat(discountPrice) * qty);
  };

  const { selectedCompany, setUpdateOrder, updateOrder } =
    useContext(AuthContext);
  const company_id = "1";
  const isMobile = useMediaQuery("(max-width : 600px)");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // const handleAddOrder

  //   const handleAddLocation = () => {
  //     api.post("location", { company_id, location }).then((response) => {
  //       console.log(response.data);
  //       getLocations();
  //     });
  //     setLocation("");
  //   };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // getDieselPrice();
    getAssetData();
  }, []);

  const [assetData, setAssetData] = useState([]);

  const getDieselPrice = async () => {
    await api.get(`get-diesel-price/${company_id}`).then((response) => {
      console.log("rrrrrrrrrr", response.data);
      setDieselPrice(response.data.diesel_price);
      setDiscountPrice(response.data.discount ? response.data.discount : 0);
    });
  };

  const getAssetData = async () => {
    await api
      .get("romulus-assets",{params:{type:'Bowser'}})
      .then((response) => {
        console.log("11111111444444", response.data);
        setAssetData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.reload();
        }
      });
  };

  const assetIcons = {
    All: require("../../assets/industry.png"),
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DgSet: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    Others: require("../../assets/oil-pump.png"),
  };

  // const [assetData, setAssetData] = React.useState([]);

  const handleSubmit = () => {

    const details = {
      order:order,
      bowser: asset,
      status: "ongoing",
      staff_1: token.id,
      // quantity: quantity,
      // company: company_id,
      // ordered_user_type: "company",
      // by_admin:''
      // diesel_price: dieselPrice,
      // discount_price: discountPrice,
      // total_price: total,
      // order_type: orderType,
      // by_admin: true,
      // ordered_admin: token.id,
    };
    console.log(details);

    api
      .post("start-delivery", details)
      .then((response) => {
        setUpdateOrder(!updateOrder);
        toast.success("Delivery Started Successfully");
        window.location.reload()
        
        
      })
      .catch((error) => {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
        toast.error(error.response.data);
        // if (error.response.status === 409) {

        //   window.location.reload();
        // }
      });
  };

  return (
    <div>
      <Button variant='contained' onClick={handleOpen} color="primary">
       Start Delivery
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: isMobile ? "90vw" : "50vw",
          }}
        >
          {/* <Box display='flex' gap='4px' flexDirection='column'>
            <Box
              sx={{
                
                bgcolor: colors.grey[900],
                borderRadius: "4px",
                p: 2,
                color: "black",
              }}
            >
              <Typography sx={{color:colors.grey[100]}}>Location 1&nbsp;:&nbsp;</Typography>
              <Typography>
                {data?.location}
              </Typography>
              <Button variant='contained'>Delete</Button>
            </Box>

            {data?.location2 !== ''?
            
            <Box
              sx={{
                bgcolor: colors.grey[900],
                borderRadius: "4px",
                p: 2,
                color: "black",
              }}
            >
              <Typography sx={{color:colors.grey[100]}}>Location 2&nbsp;:&nbsp;</Typography>
              <Typography>
                
              </Typography>
              <Button variant='contained'>Delete</Button>
            </Box>
            :''}

          </Box> */}

          {/* Add location section */}

          <Typography
            sx={{ fontWeight: "600", textAlign: "center" }}
            variant="h3"
            gutterBottom
          >
            Select Delivery Bowser
          </Typography>

          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Age"
              // onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}

          <FormControl fullWidth margin="normal">
            <InputLabel id="asset-state-label">
              Select Delivery Bowser
            </InputLabel>
            <Select
              name="asset_incharge"
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
              variant="filled"
            >
              {assetData.map((obj) => {
                let assetIcon = assetIcons["All"];
                if (obj.asset_type === "Datum") {
                  assetIcon = assetIcons["Datum"];
                } else {
                  assetIcon = assetIcons["HeavyVehicle"];
                } 
                return (
                  <MenuItem value={obj.id}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      gap="5px"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="row" gap="6px">
                        <img
                          src={assetIcon}
                          style={{ filter: colors.pngcol["filter"] }}
                          width="35"
                          height="35"
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginTop="-3px"
                        >
                          <Typography fontWeight="700">
                            {obj.reg_no}
                          </Typography>

                          <Typography fontSize="13px">
                            {obj.name}
                          </Typography>
                        </Box>
                      </Box>

                      <Box marginTop="-3px" width="25%" paddingLeft="5px">
                        <Typography>
                          {/* {obj.assetRegistrationNumber !== ""
                            ? `Reg No : ${obj.assetRegistrationNumber}`
                            : `Capacity : ${obj.capacity} Ltrs`} */}
                            Capacity : {obj.capacity}
                        </Typography>

                        {/* <Typography variant="p" fontSize="14px">
                          Location:dfsdfsd
                        </Typography> */}
                      </Box>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
            label="Totalier Reading"
            value={quantity}
            // onChange={handleLocationChange}
            onChange={(e) => {
              handleQty(e.target.value);
            }}
            fullWidth
          /> */}

       
          {/* <FormGroup
            sx={{ border: "solid 1px", borderRadius: "3px", mb: 1, pl: 1 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setBypassTotalizer(!bypassTotalizer);
                  }}
                  checked={bypassTotalizer}
                />
              }
              label="Bypass Totalizer Check"
            />
          </FormGroup> */}

          {/* <Box sx={{border:'solid 1px',p:1}}>
            <Typography variant='h5'>Diesel Price : 92.5</Typography>
            <Typography variant='h5'>Total Cost : 92.5</Typography>
          </Box> */}
          {/* <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            >
            Add
          </Button>
            </Box> */}
          {/* <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              sx={{
                border: "solid 1px",
                p: 1,
                borderRadius: 1,
                mb: 1,
                width: !isMobile ? "50%" : "",
              }}
            >
              <LocalGasStation sx={{ fontSize: "45px" }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item sm={6}>
                  <Typography fontSize="15px">Diesel Price:</Typography>
                  <Typography fontSize="15px">Discount Price:</Typography>
                  <Typography fontSize="15px">Sub Total:</Typography>
                  <Typography fontSize="15px">Total Discount:</Typography>
                  <Typography fontSize="15px" fontWeight="700">
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${dieselPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${discountPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${quantity * dieselPrice}`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`- Rs. ${quantity * discountPrice}`}
                  </Typography>
                  <Typography
                    fontSize="15px"
                    fontWeight="700"
                    textAlign="right"
                  >
                    <span
                      style={{
                        backgroundColor: "blue",
                        borderRadius: "5px",
                        paddingInline: "4px",
                        color: "white",
                      }}
                    >{`Rs. ${total}`}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box> */}

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button onClick={handleSubmit} size="large" variant="contained">
             Start Delivery
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StartDeliveryModal;
