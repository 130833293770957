import React, { useContext, useState, useEffect } from "react";
// import MainContext from "../../contexts/MainContext";
import api from "../../axios";
import BasicCard from "./AssetCard";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { useMediaQuery,useTheme } from "@mui/material";
import { tokens } from "../../theme";

function AssetsBox({data,updateData}) {
  // const { assetFilter } = useContext(MainContext);
  const assetFilter = 'All'
  const { token } = useContext(AuthContext);
 
  const isMobile = useMediaQuery('(max-width: 600px)');
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const dataq = [
    {
      typeOfAsset: "Heavy vehichle",
      assetName: "Asset Name",
      capacity: "500 ltrs",
      location: "Location Name",
      state: "State Name",
      fuelType: "Diesel",
    },
    {
      typeOfAsset: "Datum",
      assetName: "Asset Name",
      capacity: "500 ltrs",
      location: "Location Name",
      state: "State Name",
      fuelType: "Diesel",
    },
    {
      typeOfAsset: "Heavy vehicle",
      assetName: "Asset Name",
      capacity: "500 ltrs",
      location: "Location Name",
      state: "State Name",
      fuelType: "Diesel",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 20,
        flexDirection: "row",
        padding: 8,
        justifyContent: "center",
        alignItems: "center",
        paddingBottom:isMobile?150:50
      }}
    >
      {data.map((obj) => {
        return (
          <>
            {assetFilter === "All" ? (
              <div style={{ flexBasis: "50%", maxWidth: 320 }}>
                <BasicCard
                  updateData={updateData}
                  assetId={obj.id}
                  assetType={obj.asset_type}
                  assetName={obj.name}
                  capacity={obj.capacity}
                  location_name={obj.location_name}
                  pincode={obj.assetPincode}
                  location_id={obj.assetLocation}
                  state={obj.assetState}
                  incharge_name={obj.staff_incharge_name}
                  incharge_id={obj.staff_incharge}
                  last_order={obj.last_order_date}
                  reg_no={obj.reg_no}
                  fuelType={obj.fuelType}
                  is_totalizer_updated={obj.is_totalizer_updated}
                  last_totalizer_updated={obj.last_totalizer_updated}
                  sx={{ width: "500px" }}
                />
              </div>
            ) : assetFilter === obj.typeOfAsset ? (
              <div style={{ flexBasis: "50%", maxWidth: 320 }}>
                <BasicCard
                  updateData={updateData}
                  assetId={obj.id}
                  assetType={obj.typeOfAsset}
                  assetName={obj.assetName}
                  capacity={obj.assetCapacity}
                  location_name={obj.location_name}
                  pincode={obj.assetPincode}
                  location_id={obj.assetLocation}
                  state={obj.assetState}
                  incharge_name={obj.staff_incharge_name}
                  incharge_id={obj.staff_incharge}
                  last_order={obj.last_order_date}
                  reg_no={obj.reg_no}
                  fuelType={obj.fuelType}
                  is_totalizer_updated={obj.is_totalizer_updated}
                  last_totalizer_updated={obj.last_totalizer_updated}
                  sx={{ width: "500px" }}
                />
              </div>
            ) : (
              ""
            )}
          </>
        );
      })}
    </div>
  );
}

export default AssetsBox;
