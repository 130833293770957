import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import { CustomToast } from "../customtoast/CustomToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import BuildIcon  from '@mui/icons-material/Build ';
import SelectableCheckbox from "../AssetPage/CheckBox";
// import Carousel from "../SlickSlider";
import { AuthContext } from "../../AuthContext";
import api from "../../axios";
import { useState } from "react";
import { ErrorMessage } from "formik";
// import { coreBaseUrl } from "../../constants";

const assetType = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <AddCircleOutlineIcon />
      <Typography variant="body2">Datum</Typography>
    </Box>
  );
};

export default function AddAssetModal({ type,info, updateData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const { token } = React.useContext(AuthContext);
  const coreBaseUrl = '123'
  const company_id = token.company_id ? token.company_id : token.id;
  const handleClose = () => {
    setFormData({
    asset_type: "Bowser Truck",
    reg_no: "",
    capacity: "",
    name: "",
    location: "",
    staff_incharge: "",
    });
    setSelectedDate(null);
    setOpen(false);
  };

  
  

  const [formData, setFormData] = React.useState({
    asset_type: "Bowser Truck",
    reg_no: "",
    capacity: "",
    name: "",
    location: "",
    staff_incharge: "",
  });


  

  const assetIcons = {
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxHeight: "80vh",
    bgcolor: colors.background[900],
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const [selectedDate, setSelectedDate] = React.useState(null);

  const [userData, setUserData] = React.useState([]);

  const handleChange = (event) => {
    console.log(formData);
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAssetChange = (value) => {
    setFormData((prevData) => ({ ...prevData, ["asset_type"]: value }));
    if (value === "Datum") {
      setFormData((prevData) => ({ ...prevData, ["capacity"]: "2000" }));
    } else {
      setFormData((prevData) => ({ ...prevData, ["capacity"]: "" }));
    }
    console.log("9090909090909090", formData);
  };

  const handleQty = (value) => {
    setFormData((prevData) => ({ ...prevData, ["capacity"]: value }));
    console.log(formData);
  };

  const handleSubmit = () => {
    console.log(token.id);

    api
      .post("romulus-assets", formData)
      .then((response) => {
        console.log(response.data);
        updateData();
        handleClose();
      })
      .catch((error) => {
        console.log('1111www1',error);
        // const errorMessages = error.response.data.message;
        // const keys = Object.keys(errorMessages);
        // const values = Object.values(errorMessages);

        // toast.error(<CustomToast keys={keys} values={values} />);
        if (error.response.status === 403){
          window.location.reload()
        }
      });
  };

  const getStaff = () => {
    const params = {
      staff_only: true,
    };
    api.get(`staff/${company_id}`, { params }).then((response) => {
      setUserData(response.data);
      console.log("5555555555555555", response.data);
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    });
  };

  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    await axios.get(`${coreBaseUrl}location/${company_id}`).then((response) => {
      console.log("cdcdccd", response.data);
      setLocations(response.data);
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    });
  };

  // React.useEffect(() => {
  // if (type === 'edit'){
  //   setFormData({
  //     company: token.id,
  //     typeOfAsset: info.assetType,
  //     fuelType: "",
  //     assetState: "",
  //     assetRegistrationNumber: "",
  //     assetCapacity: "",
  //     assetName: info.assetName,
  //     assetLocation: "",
  //     assetPincode: 0,
  //     staff_incharge: "",
  //   });
  // }
  //   getLocations();
  //   getStaff();
  // }, []);

  return (
    <div>
      {type==='add'?<Button
        sx={{
          backgroundColor: "rgba(62, 67, 150, 1.0)",
          color: "white",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: colors.blueAccent[800],
          },

          "@media (max-width: 600px)": {
            fontSize: "8px",
            padding: "8px 16px",
          },
        }}
        onClick={handleOpen}
      >
        <AddCircleOutlineIcon />
        &nbsp;Add Asset
      </Button>
    :
    <Button
        sx={{
          backgroundColor: "rgba(62, 67, 150, 1.0)",
          color: "white",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: colors.blueAccent[800],
          },

          "@media (max-width: 600px)": {
            fontSize: "8px",
            padding: "8px 16px",
          },
        }}
        onClick={handleOpen}
      >
        <AddCircleOutlineIcon />
        &nbsp;Add Asset
      </Button>
    }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h3" align="center">
            Add Your Asset Details
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              variant="body1"
              sx={{ marginLeft: 1, cursor: "pointer" }}
              onClick={handleClose}
            >
              Close
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>

          <Box>
            <Grid container spacing={2}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <Typography variant="subtitle1" gutterBottom>
                  Asset Type
                </Typography>
                <Box display="flex" flexDirection="row" overflow="auto">
                  <SelectableCheckbox
                    name="Datum"
                    checked={formData.asset_type === "Datum"}
                    value="Datum"
                    onChange={() => handleAssetChange("Datum")}
                    icon={
                      <img
                        src={assetIcons["Datum"]}
                        style={{ filter: colors.pngcol["filter"] }}
                        width="25"
                        height="25"
                      />
                    }
                  />
                  <SelectableCheckbox
                    name="Bowser Truck"
                    checked={formData.asset_type === "Bowser Truck"}
                    value="Bowser Truck"
                    onChange={() => handleAssetChange("Bowser Truck")}
                    icon={
                      <img
                        src={assetIcons["HeavyVehicle"]}
                        width="30"
                        height="30"
                      />
                    }
                  />
                  
                </Box>
              </FormControl>
              {/* <Grid item xs={12} md={6}> */}
              
              

                {formData.asset_type === "Bowser Truck" ? (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      name="reg_no"
                      value={formData.reg_no}
                      label="Registration Number"
                      variant="filled"
                      onChange={handleChange}
                    />
                  </FormControl>
                ) : (
                  ""
                )}

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="name"
                    value={formData.name}
                    label="Preferred Name"
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="location"
                    value={formData.location}
                    label="Location"
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>

          

                {/* <FormControl fullWidth margin="normal">
                  <TextField
                    name="assetPincode"
                    value={formData.assetPincode}
                    label="Pincode"
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl> */}

                 {formData.asset_type !== "Datum" ? (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      name="capacity"
                      value={formData.capacity}
                      label="Capacity in Liters"
                      variant="filled"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        display: "flex",
                        marginTop: "8px",
                        gap: 5,
                        overflow: "auto",
                        paddingTop: "5px",
                        "&::-webkit-scrollbar": {
                          width: "10px",
                        },
                      }}
                    >
                      
                     
                      
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("500");
                        }}
                      >
                        500
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("1000");
                        }}
                      >
                        1000
                      </Button>

  <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("2000");
                        }}
                      >
                        2000
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("");
                        }}
                      >
                        Custom
                      </Button>
                    </div>
                  </FormControl>
                ) : (
                  ""
                )}

              </Grid>

              {/* <Grid item xs={12} md={6}> */}
               

                {/* <FormControl fullWidth margin="normal">
                  <InputLabel id="asset-state-label">Staff Incharge</InputLabel>
                  <Select
                    name="staff_incharge"
                    value={formData.staff_incharge}
                    onChange={handleChange}
                    variant="filled"
                  >
                    {userData.map((obj) => {
                      return (
                        <MenuItem value={obj.id}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            gap="5px"
                            justifyContent="space-between"
                          >
                            <Box display="flex" flexDirection="row" gap="6px">
                              <Avatar />
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginTop="-3px"
                              >
                                <Typography fontWeight="700">
                                  {obj.username}
                                </Typography>

                                <Typography fontSize="13px">
                                  {obj.number}
                                </Typography>
                              </Box>
                            </Box>

                    
                          </Box>
                        </MenuItem>
                      );
                    })}

                  </Select>
                </FormControl> */}


              {/* </Grid> */}
            {/* </Grid> */}
          </Box>

          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: "#3e4396",
              alignItems: "center",
              width: "30%",
              ml: "35%",
              mt: "20px",
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
