export const userBaseurl = 'https://liveserver.romulusoil.com/user/'
export const adminBaseurl = 'https://liveserver.romulusoil.com/';
export const Baseurl = 'https://liveserver.romulusoil.com/staff'

// export const userBaseurl = 'http://localhost:8000/user/'
// export const adminBaseurl = "http://localhost:8000/";
// export const Baseurl = 'http://localhost:8000/staff'


// export const adminBaseurl = 'https://devserver.romulusoil.com/'
// export const userBaseurl = 'https://devserver.romulusoil.com/user/'
