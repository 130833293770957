import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  useMediaQuery,
  Grid,
  Input,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
import {
  Add,
  AddCircleOutline,
  HdrPlus,
  LocalGasStation,
  PlusOne,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

const FinishDeliveryModal = ({ open2, onClose, order }) => {
  const [open, setOpen] = useState(true);
  const [quantity, setQuantity] = useState("");
  const [asset, setAsset] = useState("");
  const [orderType, setOrderType] = useState("");
  // const [bypassTotalizer, setBypassTotalizer] = useState(false);
  const [total, setTotal] = useState("");
  // const [location2,setLocation2] = useState('');
  // const dieselPrice = 92.5

  // const isMobile = useMediaQuery("(max-width : 600px)");
  const [dieselPrice, setDieselPrice] = useState(90);
  const [discountPrice, setDiscountPrice] = useState(1);

  const { token } = useContext(AuthContext);

  const [chalanNo, setChalanNo] = useState('')
  const [chalanImage,setChalanImage] = useState()

  const handleQty = (qty) => {
    console.log(".....................", qty);
    setQuantity(qty);

    setTotal(parseFloat(dieselPrice) * qty - parseFloat(discountPrice) * qty);
  };

  const { selectedCompany, setUpdateOrder, updateOrder } =
    useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width : 600px)");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // const handleAddOrder

  //   const handleAddLocation = () => {
  //     api.post("location", { company_id, location }).then((response) => {
  //       console.log(response.data);
  //       getLocations();
  //     });
  //     setLocation("");
  //   };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

      const getAssetData = async () => {
        await api
          .get("romulus-assets", { params: { type: "Bowser" } })
          .then((response) => {
            console.log("11111111444444", response.data);
            setAssetData(response.data);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              window.location.reload();
            }
          });
      };


  useEffect(() => {
    // getDieselPrice();
    getAssetData();
  }, []);

  const [assetData, setAssetData] = useState([]);

  

  const assetIcons = {
    All: require("../../assets/industry.png"),
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DgSet: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    Others: require("../../assets/oil-pump.png"),
  };

  // const [assetData, setAssetData] = React.useState([]);

  const navigate = useNavigate()
  const handleSubmit = () => {
 const formData = new FormData();
//  formData.append("bowser", asset);
 formData.append("totalizer_reading", reading);
 formData.append("chalan_no", chalanNo);
 formData.append("chalan_image", chalanImage); // Make sure you have set chalanImage when handling file input change
 formData.append("delivery_id", token.delivery_id);
    api
      .post("finish-delivery", formData, {
        headers: {
          "Content-Type": "multipart/form-data", 
        },
      })
      .then((response) => {
        setUpdateOrder(!updateOrder);
        handleClose();
        toast.success("Delivery Completed Successfully");

        navigate('/orders')
        window.location.reload()
      })
      .catch((error) => {
        toast.error(error.response.data);
        if (error.response.status === 409) {
          window.location.reload();
        }
      });
  };

  const [reading,setReading] = useState('')
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: isMobile ? "90vw" : "50vw",
          }}
        >
          <Typography
            sx={{ fontWeight: "600", textAlign: "center" }}
            variant="h3"
            gutterBottom
          >
            Enter Details
          </Typography>

          {/* <FormControl fullWidth margin="normal">
            <InputLabel id="asset-state-label">
              Select Delivery Bowser
            </InputLabel>
            <Select
              name="asset_incharge"
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
              variant="filled"
            >
              {assetData.map((obj) => {
                let assetIcon = assetIcons["All"];
                if (obj.asset_type === "Datum") {
                  assetIcon = assetIcons["Datum"];
                } else {
                  assetIcon = assetIcons["HeavyVehicle"];
                }
                return (
                  <MenuItem value={obj.id}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      gap="5px"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="row" gap="6px">
                        <img
                          src={assetIcon}
                          style={{ filter: colors.pngcol["filter"] }}
                          width="35"
                          height="35"
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginTop="-3px"
                        >
                          <Typography fontWeight="700">{obj.reg_no}</Typography>

                          <Typography fontSize="13px">{obj.name}</Typography>
                        </Box>
                      </Box>

                      <Box marginTop="-3px" width="25%" paddingLeft="5px">
                        <Typography>Capacity : {obj.capacity}</Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <TextField
            label="Totalizer Reading"
            value={reading}
            onChange={(e) => {
              setReading(e.target.value);
            }}
            fullWidth
          />

          <TextField
            label="Chalan No"
            value={chalanNo}
            sx={{ mt: 1 }}
            onChange={(e) => {
              setChalanNo(e.target.value);
            }}
            fullWidth
          />

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="fileUpload"
            >
              Upload Chalan Image
            </label>
            <input
              type="file"
              id="fileUpload"
              onChange={(e)=>setChalanImage(e.target.files[0])}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button onClick={handleSubmit} size="large" variant="contained">
              Finish Delivery
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FinishDeliveryModal;
