import React, { useContext } from 'react'
import Sidebar from './scenes/global/Sidebar'
import Topbar from './scenes/global/Topbar'
import Dashboard from "./scenes/dashboard";
import CompanyManagement from "./Pages/CompanyManagement";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { Routes, Route } from "react-router-dom";
import UnderDev from "./progres/UnderDev";
import Calendar from "./scenes/calendar/calendar";
import UnderDevad from './Admin/UnderDevad'
import { useState } from 'react';
import Orders from './Pages/Orders';
import RomulusAssets from './Pages/RomulusAssets';
import Delivery from './Pages/Delivery';
import { AuthContext } from './AuthContext';
import { Box } from '@mui/material';
// import StaffPage from './Pages/Employees';

function AdminContainer() {
    const [isSidebar, setIsSidebar] = useState(true);
    const {token} = useContext(AuthContext)
    return (
      <>
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Routes>
            {/* <Route path="/" element={<Dashboard />} /> */}
            <Route path="/" element={<UnderDevad />} />
            {/* <Route path="/company" element={<CompanyManagement />} /> */}
            <Route path="/assets" element={<RomulusAssets />} />
            {/* <Route path="/staff" element={<StaffPage />} /> */}
            <Route path="/orders" element={<Orders />} />
            <Route path="/delivery" element={token.delivery_open?<Delivery />:<Box>Delivery Not Open</Box>} />
            {/* <Route path="/contacts" element={<Contacts />} /> */}
            {/* <Route path="/contacts" element={<UnderDev />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/geography" element={<Geography />} /> */}
          </Routes>
        </main>
      </>
    );
}

export default AdminContainer