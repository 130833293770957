import { Box, Typography, useTheme,Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";

import Header from "../components/Header";
import AddCompanyModal from "../components/Modals/AddCompanyModal";
import BasicTable from "../components/Tables/BasicTable";
// import HistoryBox from "../components/OrdersPage/OrdersBox";
import axios from 'axios'
import { useEffect, useState } from "react";
import { getRoles } from "@testing-library/react";
import api from "../axios";
import HistoryBox from "../components/Tables/OrdersTable";
// import OrderBox

const Orders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 
  const [rowData,setRowData] = useState([])

  // const getRows = () => {
  //   api.get('company').then((response)=>{
  //     console.log(response.data);
  //     setRowData(response.data)
  //   })
  // }

  // useEffect(() => {
  //   getRows()
  // }, [])
  
  return (
    <Box sx={{marginInline:"20px"}}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="Orders" subtitle="All Orders" />        
      <Box>
          {/* <AddCompanyModal ></AddCompanyModal> */}
            </Box>
        </Box>

      <Box>
        <HistoryBox fetch_type='all' />
      </Box>
      
    </Box>
  );
};

export default Orders;
