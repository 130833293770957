import axios from 'axios';
import { Baseurl,adminBaseurl,userBaseurl } from './constants';


const createAxiosInstance = () => {
  const baseURL = process.env.REACT_APP_STAFF_BASEURL
  const instance = axios.create({
    baseURL,
    withCredentials: true,
  });

  // Add a response interceptor
  // instance.interceptors.response.use(
  //   response => response,  // Pass through successful responses unchanged
  //   error => {
  //     if (error.response && error.response.status === 403) {
  //       window.location.reload();
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  return instance;
};

const createLoginAxiosInstance = (baseUrl) => {
  const instance = axios.create({
    baseURL:adminBaseurl,
    withCredentials: true,
  });
}

const userApi = axios.create({
  baseURL: userBaseurl,
  withCredentials: true,
});

const api = createAxiosInstance(Baseurl);
const loginApi = createLoginAxiosInstance(adminBaseurl)

export {loginApi};
export default api ;