import React, { useContext, useEffect, useState } from "react";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";

function DeliveryBox() {
  const [distribution, setDistribution] = useState([]);
  const { token, distributionUpdate } = useContext(AuthContext);

  const isMobile = useMediaQuery("(max-width : 600px)");
  const getData = () => {
    api.get(`order-distribution/${token.delivery_id}`).then((response) => {
      console.log("====================================");
      console.log(response.data);
      console.log("====================================");
      setDistribution(response.data);
    });
  };

  const assetIcons = {
    All: require("../../assets/industry.png"),
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DgSet: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    Others: require("../../assets/oil-pump.png"),
  };

  

  useEffect(() => {
    getData();
  }, [distributionUpdate]);

  return (
    <div>
      {distribution.map((obj) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingInline: isMobile ? 1 : 2,
              paddingBlock: isMobile ? 0.3 : 0.5,
              border: "1px solid darkgrey",
              m: isMobile ? 0 : 1,
              borderRadius: "6px",
            }}
          >
            {/* <Box>
                <img src={assetIcons['Datum']} width='30' height='30'/>
                <Typography>Datum</Typography>
            </Box> */}

            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              gap="5px"
              justifyContent="space-between"
            >
              <Box className="w-[40px]"  display="flex" flexDirection="row" alignItems="center">
                <img
                  src={assetIcons["HeavyVehicle"]}
                  //   style={{ filter: colors.pngcol["filter"] }}
                  width="35"
                  height="35"
                />
                <Box display="flex" flexDirection="column" marginTop="-3px">
                  <Typography fontWeight="700">
                    {/* {obj.typeOfAsset === "Heavy vehicle"
                      ? obj.assetRegistrationNumber
                      : obj.assetName} */}

                    {obj.asset_regno
                      ? obj.asset_regno
                      : obj.asset_name}
                  </Typography>

                  <Typography fontSize="13px">
                    {/* {obj.typeOfAsset} */}
                    {obj.asset_type}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography>Quantity</Typography>
                <Box display="flex" flexDirection="row">
                  <Typography
                    // sx={{ border: "1px solid blue", borderRadius: "8px",paddingInline:0.5}}
                    sx={{ fontWeight: "700" }}
                  >
                    {obj.quantity}&nbsp;
                  </Typography>

                  <span> Ltrs</span>
                </Box>
              </Box>

              <Box sx={{ maxWidth: isMobile ? "65px" : "8%" }}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "firebrick",
                    mb: 0.4,
                    maxWidth: isMobile ? "40px" : "70px",
                  }}
                >
                  <DeleteForever sx={{ color: "white" }} />
                </Button>

                <Button sx={{ bgcolor: "darkcyan" }}>
                  <Edit sx={{ color: "white" }} />
                </Button>
              </Box>
            </Box>
          </Box>
        );
      })}
    </div>
  );
}

export default DeliveryBox;
