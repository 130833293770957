import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  useMediaQuery,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
import { Add, AddCircleOutline, HdrPlus, LocalGasStation, PlusOne } from "@mui/icons-material";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";

const AddDeliveryModal = ({ onClose, updateData }) => {
  const [open, setOpen] = useState(true);
  const [quantity, setQuantity] = useState("");
  const [asset, setAsset] = useState("");
  const [orderType, setOrderType] = useState("");
  const [bypassTotalizer, setBypassTotalizer] = useState(false);
  const [total, setTotal] = useState("");
  // const [location2,setLocation2] = useState('');
  // const dieselPrice = 92.5

  // const isMobile = useMediaQuery("(max-width : 600px)");
  const [dieselPrice, setDieselPrice] = useState(90);
  const [discountPrice, setDiscountPrice] = useState(1);
  const { token } = useContext(AuthContext);

  const handleQty = (qty) => {
    console.log(".....................", qty);
    setQuantity(qty);

    setTotal(parseFloat(dieselPrice) * qty - parseFloat(discountPrice) * qty);
  };

  const { selectedCompany, setUpdateOrder, updateOrder } =
    useContext(AuthContext);
  const company_id = "1";
  const isMobile = useMediaQuery("(max-width : 600px)");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // const handleAddOrder

  //   const handleAddLocation = () => {
  //     api.post("location", { company_id, location }).then((response) => {
  //       console.log(response.data);
  //       getLocations();
  //     });
  //     setLocation("");
  //   };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    getDieselPrice();
    getAssetData();
  }, []);

  const [assetData, setAssetData] = useState([]);

  const getDieselPrice = async () => {
    await api.get(`get-diesel-price/${company_id}`).then((response) => {
      console.log("rrrrrrrrrr", response.data);
      setDieselPrice(response.data.diesel_price);
      setDiscountPrice(response.data.discount ? response.data.discount : 0);
    });
  };

  const getAssetData = async () => {
    await api.get(`assets/${company_id}`).then((response) => {
      setAssetData(response.data);
    });
  };

  const assetIcons = {
    All: require("../../assets/industry.png"),
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DgSet: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    Others: require("../../assets/oil-pump.png"),
  };

  const [userData, setUserData] = React.useState([]);

  const handleSubmit = () => {
    const details = {
      asset: asset,
      quantity: quantity,
      company: company_id,
      ordered_by: company_id,
      ordered_user_type: "company",
      diesel_price: dieselPrice,
      discount_price: discountPrice,
      total_price: total,
      order_status: "ordered",
      order_type: orderType,
      bypass_totalizer: bypassTotalizer,
      by_admin: true,
      ordered_admin: token.id,
    };
    console.log(details);
    api
      .post("order", details)
      .then((response) => {
        setUpdateOrder(!updateOrder);
        handleClose();
        toast.success("Order Placed Successfully");
      })
      .catch((error) => {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
        toast.error(error.response.data.message);
        if (error.response.status === 409) {
          console.log("====================================");
          console.log("ttttt");
          console.log("====================================");
          window.location.reload();
        }
      });
  };

  return (
    <div>
      <Button onClick={handleOpen} color="primary">
        <Add /> Add Order
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: isMobile ? "90vw" : "70vw",
          }}
        >
          {/* <Box display='flex' gap='4px' flexDirection='column'>
            <Box
              sx={{
                
                bgcolor: colors.grey[900],
                borderRadius: "4px",
                p: 2,
                color: "black",
              }}
            >
              <Typography sx={{color:colors.grey[100]}}>Location 1&nbsp;:&nbsp;</Typography>
              <Typography>
                {data?.location}
              </Typography>
              <Button variant='contained'>Delete</Button>
            </Box>

            {data?.location2 !== ''?
            
            <Box
              sx={{
                bgcolor: colors.grey[900],
                borderRadius: "4px",
                p: 2,
                color: "black",
              }}
            >
              <Typography sx={{color:colors.grey[100]}}>Location 2&nbsp;:&nbsp;</Typography>
              <Typography>
                
              </Typography>
              <Button variant='contained'>Delete</Button>
            </Box>
            :''}

          </Box> */}

          {/* Add location section */}

          <Typography
            sx={{ fontWeight: "600", textAlign: "center" }}
            variant="h3"
            gutterBottom
          >
            Add Delivery
          </Typography>

          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Age"
              // onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}

          <FormControl fullWidth margin="normal">
            <InputLabel id="asset-state-label">
              Select Delivery Bowser
            </InputLabel>
            <Select
              name="asset_incharge"
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
              variant="filled"
            >
              {assetData.map((obj) => {
                let assetIcon = assetIcons["All"];
                if (obj.typeOfAsset === "Datum") {
                  assetIcon = assetIcons["Datum"];
                } else if (obj.typeOfAsset === "Heavy vehicle") {
                  assetIcon = assetIcons["HeavyVehicle"];
                } else if (obj.typeOfAsset === "Infra machine") {
                  assetIcon = assetIcons["InfraMachine"];
                }
                return (
                  <MenuItem value={obj.id}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      gap="5px"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="row" gap="6px">
                        <img
                          src={assetIcon}
                          style={{ filter: colors.pngcol["filter"] }}
                          width="35"
                          height="35"
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginTop="-3px"
                        >
                          <Typography fontWeight="700">
                            {obj.assetName}
                          </Typography>

                          <Typography fontSize="13px">
                            {obj.typeOfAsset}
                          </Typography>
                        </Box>
                      </Box>

                      <Box marginTop="-3px" width="25%" paddingLeft="5px">
                        <Typography>
                          {obj.assetRegistrationNumber !== ""
                            ? `Reg No : ${obj.assetRegistrationNumber}`
                            : `Capacity : ${obj.assetCapacity} Ltrs`}
                        </Typography>

                        {/* <Typography variant="p" fontSize="14px">
                          Location:dfsdfsd
                        </Typography> */}
                      </Box>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
            label="Totalier Reading"
            value={quantity}
            // onChange={handleLocationChange}
            onChange={(e) => {
              handleQty(e.target.value);
            }}
            fullWidth
          /> */}

          <FormGroup
            sx={{
              // border: "solid 1px darkgrey",
              borderRadius: "3px",
              mb: 1,
              pl: 1,
              display: "flex",
              flexDirection: "row",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.6)",
            }}
          >
            <FormControl sx={{ minWidth: "40%", mb: 1 }} margin="normal">
              <InputLabel id="asset-state-label">Select Asset</InputLabel>
              <Select
                sx={{ mb: 1 }}
                name="asset_incharge"
                value={asset}
                onChange={(e) => setAsset(e.target.value)}
                variant="filled"
              >
                {assetData.map((obj) => {
                  let assetIcon = assetIcons["All"];
                  if (obj.typeOfAsset === "Datum") {
                    assetIcon = assetIcons["Datum"];
                  } else if (obj.typeOfAsset === "Heavy vehicle") {
                    assetIcon = assetIcons["HeavyVehicle"];
                  } else if (obj.typeOfAsset === "Infra machine") {
                    assetIcon = assetIcons["InfraMachine"];
                  }
                  return (
                    <MenuItem value={obj.id}>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        gap="5px"
                        justifyContent="space-between"
                      >
                        <Box display="flex" flexDirection="row" gap="6px">
                          <img
                            src={assetIcon}
                            style={{ filter: colors.pngcol["filter"] }}
                            width="35"
                            height="35"
                          />
                          <Box
                            display="flex"
                            flexDirection="column"
                            marginTop="-3px"
                          >
                            <Typography fontWeight="700">
                              {obj.assetName}
                            </Typography>

                            <Typography fontSize="13px">
                              {obj.typeOfAsset}
                            </Typography>
                          </Box>
                        </Box>

                        <Box marginTop="-3px" width="25%" paddingLeft="5px">
                          <Typography>
                            {obj.assetRegistrationNumber !== ""
                              ? `Reg No : ${obj.assetRegistrationNumber}`
                              : `Capacity : ${obj.assetCapacity} Ltrs`}
                          </Typography>

                          {/* <Typography variant="p" fontSize="14px">
                          Location:dfsdfsd
                        </Typography> */}
                        </Box>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              label="Quantity"
              value={quantity}
              // onChange={handleLocationChange}
              onChange={(e) => {
                handleQty(e.target.value);
              }}
            />

            <Button variant="contained">
              <AddCircleOutline />
              Add{" "}
            </Button>
          </FormGroup>

          <Box></Box>
          {/* <FormGroup
            sx={{ border: "solid 1px", borderRadius: "3px", mb: 1, pl: 1 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setBypassTotalizer(!bypassTotalizer);
                  }}
                  checked={bypassTotalizer}
                />
              }
              label="Bypass Totalizer Check"
            />
          </FormGroup> */}

          {/* <Box sx={{border:'solid 1px',p:1}}>
            <Typography variant='h5'>Diesel Price : 92.5</Typography>
            <Typography variant='h5'>Total Cost : 92.5</Typography>
          </Box> */}
          {/* <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            >
            Add
          </Button>
            </Box> */}
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              sx={{
                border: "solid 1px",
                p: 1,
                borderRadius: 1,
                mb: 1,
                width: !isMobile ? "50%" : "",
              }}
            >
              <LocalGasStation sx={{ fontSize: "45px" }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item sm={6}>
                  <Typography fontSize="15px">Diesel Price:</Typography>
                  <Typography fontSize="15px">Discount Price:</Typography>
                  <Typography fontSize="15px">Sub Total:</Typography>
                  <Typography fontSize="15px">Total Discount:</Typography>
                  <Typography fontSize="15px" fontWeight="700">
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${dieselPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${discountPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${quantity * dieselPrice}`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`- Rs. ${quantity * discountPrice}`}
                  </Typography>
                  <Typography
                    fontSize="15px"
                    fontWeight="700"
                    textAlign="right"
                  >
                    <span
                      style={{
                        backgroundColor: "blue",
                        borderRadius: "5px",
                        paddingInline: "4px",
                        color: "white",
                      }}
                    >{`Rs. ${total}`}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button onClick={handleSubmit} size="large" variant="contained">
              Order Now
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddDeliveryModal;
