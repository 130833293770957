import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import AddCompanyModal from "../components/Modals/AddCompanyModal";
import BasicTable from "../components/Tables/BasicTable";
import axios from "axios";
import { useEffect, useState } from "react";
import { getRoles } from "@testing-library/react";
import api from "../axios";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";
import AssetsBox from "../components/AssetPage/AssetsBox";
import AddAssetModal from "../components/AssetPage/AddAssetModal";
import DeliveryBox from "../components/DeliveryPage/DeliveryBox";
import { AddCircle } from "@mui/icons-material";
import FinishDeliveryModal from "../components/DeliveryPage/FinishDeliveryModal";
import { toast } from "react-toastify";

const Delivery = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { distributionUpdate, setDistributionUpdate } = useContext(AuthContext);

  const [rowData, setRowData] = useState([]);
  const assetIcons = {
    All: require("../assets/industry.png"),
    Datum: require("../assets/datum.png"),
    HeavyVehicle: require("../assets/truck.png"),
    InfraMachine: require("../assets/citytech-logo.png"),
    DgSet: require("../assets/generator.png"),
    Industrial: require("../assets/factory.png"),
    Others: require("../assets/oil-pump.png"),
  };

  const [asset, setAsset] = useState();

  const { token } = useContext(AuthContext);
  const getRows = async () => {
    await api
      .get(`delivery-data/${token.delivery_id}`)
      .then((response) => {
        console.log("11111111444444", response.data);
        setRowData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.reload();
        }
      });
  };

  const handleSubmit = () => {
    const details = {
      asset,
      delivery: token.delivery_id,
      quantity,
    };
    api
      .post("order-distribution", details)
      .then((response) => {
        setDistributionUpdate(!distributionUpdate)
      })
      .catch((error) => {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        toast.error('Something Went Wrong..!Please Try Again')
      });
      // hello
      // window.location.reload()
  };

  const handleFinish = () => {
    setFinishOpen(true)
   
  }

  useEffect(() => {
    getRows();
  }, []);

  const [quantity, setQuantity] = useState();
  const isMobile = useMediaQuery("(max-width : 600px)");
  const [finishOpen, setFinishOpen] = useState(false)
  return (
    <Box sx={{ marginInline: "20px" }}>
      {/* <Typography>Delivery Open</Typography> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: "darkgrey",
          padding: 1,
          borderRadius: "14px",
          minHeight: "100px",
          gap: 2,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
          // background: "linear-gradient(45deg, #393939, #3b3b3b);",
        }}
      >
        <FormControl
          fullWidth
          // margin="normal"
          sx={{
            bgcolor: "white",
            borderRadius: "8px",
            "& .MuiInputBase-root": {
              marginBottom: "0", // Set the marginBottom to 0 for the MuiInputBase-root class
            },
          }}
        >
          <InputLabel id="asset-state-label">Select an asset</InputLabel>
          <Select
            sx={{ mb: 1 }}
            name="asset_incharge"
            value={asset}
            onChange={(e) => setAsset(e.target.value)}
            variant="filled"
          >
            {rowData.map((obj) => {
              let assetIcon = assetIcons["All"];
              if (obj.typeOfAsset === "Datum") {
                assetIcon = assetIcons["Datum"];
              } else if (obj.typeOfAsset === "Heavy vehicle") {
                assetIcon = assetIcons["HeavyVehicle"];
              } else if (obj.typeOfAsset === "Infra machine") {
                assetIcon = assetIcons["InfraMachine"];
              }
              return (
                <MenuItem value={obj.id}>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    gap="5px"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" gap="6px">
                      <img
                        src={assetIcon}
                        style={{ filter: colors.pngcol["filter"] }}
                        width="35"
                        height="35"
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        marginTop="-3px"
                      >
                        <Typography fontWeight="700">
                          {obj.typeOfAsset === "Heavy vehicle"
                            ? obj.assetRegistrationNumber
                            : obj.assetName}
                        </Typography>

                        <Typography fontSize="13px">
                          {obj.typeOfAsset}
                        </Typography>
                      </Box>
                    </Box>

                    {/* <Box marginTop="-3px" width="25%" paddingLeft="5px">
                      <Typography>
                        {obj.assetRegistrationNumber !== ""
                          ? `Reg No : ${obj.assetRegistrationNumber}`
                          : `Capacity : ${obj.assetCapacity} Ltrs`}
                      </Typography>
                    </Box> */}
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          sx={{ bgcolor: "white", borderRadius: "8px" }}
          label="Quantity"
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
          fullWidth
        />

        <Button
          sx={{
            minWidth: "120px",
            bgcolor: "green",
            height: "50px",
            // color: "black",
            fontWeight: "700",
            fontSize: "1rem",
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          <AddCircle /> Add
        </Button>

        {/* <Header title="Assets" subtitle="Managing Romulus Assets" /> */}

        <Box>{/* <AddAssetModal updateTable={getRows} /> */}</Box>
      </Box>

      <Box>
        <Box
          sx={{
            // border: "1px solid darkgrey",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
            // maxHeight: '',
            mt: 1,
            height: isMobile ? "70vh" : "50vh",
            borderRadius: "10px",
            overflowX: "auto",
          }}
        >
          <DeliveryBox />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="10px"
        >
          {
            finishOpen?<FinishDeliveryModal onClose={()=>setFinishOpen(false)} />:''
          }
          

          <Button variant="contained" onClick={handleFinish}>Finish Delivery</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Delivery;
